import React from 'react';
import loading from '../assets/backgrounds/bg-loading.jpg';

const Loader = React.forwardRef((props, ref) => {
	console.log(ref);
	return (
		<article
			ref={ref}
			className="loader"
		>
			<img
				src={loading}
				alt="Loading Page"
			/>
		</article>
	);
});

export default Loader;
