import { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { ReactComponent as Dot } from '../../assets/icons/icon-middot.svg';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';

function Reviews() {
	const [reviews, setReviews] = useState([]);

	useEffect(function () {
		async function fetchModules() {
			const res = await fetch(
				'https://cms.frescoyfino.com/wp-json/wp/v2/reviews?acf_format=standard'
			);
			const obj = await res.json();
			setReviews(obj);
		}
		fetchModules();
	}, []);

	console.log(reviews);
	return (
		<article className="reviews">
			<section className="reviews__title section__title">
				<Dot />
				<h3>TESTIMONIOS</h3>
			</section>

			<Splide
				options={{
					perPage: 1,
					perMove: 1,
					arrows: false,
					pagination: true,
					// autoplay: true,
					// interval: 3000,
					type: 'loop',
				}}
			>
				{reviews.map((review) => {
					console.log(review.acf);
					return (
						<SplideSlide key={review.id}>
							<p className="slide__review">
								{parse(review.acf.review)}
							</p>

							<div className="slide__reviewer">
								<img
									src={review.acf.logo}
									alt=""
									className="slide__logo"
								/>
								<div className="slide__content">
									<p className="">{review.acf.reviewer}</p>
									<div className="slide__wrapper">
										<p className="slide__position">
											{review.acf.position}
										</p>
										<span>&middot;</span>
										<p className="slide__company">
											{review.acf.company}
										</p>
									</div>
								</div>
							</div>
						</SplideSlide>
					);
				})}
			</Splide>
		</article>
	);
}

export default Reviews;
