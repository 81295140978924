import Grid from '../Grid/Grid';
import { Content } from './Content';

const Work = ({ data }) => {
	return (
		<article className="work">
			<Content data={data} />
			<Grid data={data} />
		</article>
	);
};

export default Work;
