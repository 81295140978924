// import { useEffect, useState } from 'react';
import Card from '../../components/Cards/Card';
import redsVideo from '../../assets/videos/video-wereds.mp4';
import ggVideo from '../../assets/videos/video-gomezgrau.mp4';

function Grid({ data }) {
	// const [projects, setProjects] = useState([]);

	// useEffect(function () {
	// 	async function fetchModules() {
	// 		const res = await fetch(
	// 			'https://cms.frescoyfino.com/wp-json/wp/v2/project?acf_format=standard'
	// 		);
	// 		const obj = await res.json();
	// 		setProjects(obj);
	// 	}
	// 	fetchModules();
	// }, []);

	return (
		<article className="grid">
			<Card
				title="Gómez Grau"
				categories="Desarrollo Web & Diseño Web"
				url="https://www.gomezgrau.com/"
				video={ggVideo}
			/>
			<Card
				title="WeReds"
				categories="Desarrollo Web"
				url="https://wereds.es/"
				video={redsVideo}
			/>
		</article>
	);
}

export default Grid;
