import { useNavigation } from 'react-router-dom';
import { gsap } from 'gsap';
import { useGSAP } from '@gsap/react';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin';
import { useRef } from 'react';

gsap.registerPlugin(useGSAP, ScrollTrigger, DrawSVGPlugin);

const Line = () => {
	const navigation = useNavigation();
	const lineBlue = useRef(null);
	const lineYellow = useRef(null);

	// console.log(props.bodyRef.current);

	useGSAP(
		() => {
			const isReady = navigation.state === 'idle';
			const footer = document.querySelector('.site-footer');
			const workModule = document.querySelector('.work');
			const tl = gsap.timeline();

			function handleLoader() {
				setTimeout(() => {
					tl.fromTo(
						lineBlue.current,
						{ drawSVG: 0 },
						{ drawSVG: '34%', duration: 1.5 }
					);
					tl.fromTo(
						lineYellow.current,
						{ drawSVG: 0 },
						{ drawSVG: '31%', duration: 2.5 },
						'<'
					);
					tl.fromTo(
						lineBlue.current,
						{ drawSVG: '34%' },
						{
							drawSVG: '100%',
							scrollTrigger: {
								trigger: workModule,
								endTrigger: footer,
								scrub: 1.5,
								// markers: true,
								start: 'top 0',
								end: 'bottom top',
							},
						}
					);
					tl.fromTo(
						lineYellow.current,
						{ drawSVG: '31%' },
						{
							drawSVG: '100%',
							scrollTrigger: {
								trigger: workModule,
								endTrigger: footer,
								scrub: 1.5,
								start: 'top 0',
								end: 'bottom top',
							},
						},
						'<'
					);
				}, 600);
			}

			if (isReady) {
				handleLoader();
			}
		},

		{ dependencies: [lineBlue, lineYellow] }
	);

	return (
		<>
			<svg
				viewBox="0 0 1682 5540"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				className="line line--blue"
			>
				<path
					d="M1723 35C1500.04 88.0048 1398.98 162.012 1332.3 279.022C1133.49 627.865 1358.48 1145.1 976.783 1530.14C595.084 1915.17 112.334 1967.68 39.9217 3024.77C-32.4907 4081.87 725.18 4106.37 278.433 5529"
					stroke="url(#paint0_linear_58_485)"
					strokeWidth="70"
					ref={lineBlue}
				/>
				<defs>
					<linearGradient
						id="paint0_linear_58_485"
						x1="879"
						y1="35"
						x2="879"
						y2="5529"
						gradientUnits="userSpaceOnUse"
					>
						<stop stopColor="#0040D6" />
						<stop
							offset="0.475"
							stopColor="#7096F1"
							stopOpacity="0.74"
						/>
						<stop
							offset="1"
							stopColor="#0040D6"
						/>
					</linearGradient>
				</defs>
			</svg>

			<svg
				viewBox="0 0 1443 6534"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				className="line line--yellow"
			>
				<path
					d="M67.8561 -247C-42.5645 781.571 405.323 173.004 156.741 1350.61C-95.4253 2545.19 1457.05 2290.53 1390.94 3682.77C1296.97 5661.91 491.527 4308.31 812.608 6984"
					stroke="url(#paint0_linear_58_484)"
					strokeWidth="100"
					ref={lineYellow}
				/>
				<defs>
					<linearGradient
						id="paint0_linear_58_484"
						x1="721.5"
						y1="-247"
						x2="721.5"
						y2="6984"
						gradientUnits="userSpaceOnUse"
					>
						<stop stopColor="#FFBD00" />
						<stop
							offset="0.165"
							stopColor="#FFBD00"
							stopOpacity="0.61"
						/>
						<stop
							offset="1"
							stopColor="#FFBD00"
						/>
					</linearGradient>
				</defs>
			</svg>
		</>
	);
};

export default Line;
