import MediaQuery from 'react-responsive';
import { ReactComponent as LogoBrand } from '../assets/logos/logo-brand.svg';
import { ReactComponent as LogoBrandSolo } from '../assets/logos/logo-brand-solo.svg';
import { ReactComponent as SvgTalk } from '../assets/icons/icon-talk.svg';

function Header() {
	function handleClick() {
		const footer = document.getElementById('footer');
		footer.scrollIntoView({
			behavior: 'smooth',
			block: 'start',
			inline: 'nearest',
		});
	}

	return (
		<header className="site__header">
			<figure className="header__start">
				<MediaQuery maxWidth={991}>
					<LogoBrandSolo />
				</MediaQuery>
				<MediaQuery minWidth={992}>
					<LogoBrand />
				</MediaQuery>
			</figure>
			<section className="header__end">
				<button
					onClick={handleClick}
					className="btn btn__primary"
				>
					<span>HABLAMOS</span>
					<SvgTalk />
				</button>
			</section>
		</header>
	);
}

export default Header;
