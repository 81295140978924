export async function getAll() {
	const res = await fetch(
		'https://cms.frescoyfino.com/wp-json/wp/v2/pages/10?acf_format=standard'
	);
	// fetch won't throw error on 400 errors (e.g. when URL is wrong), so we need to do it manually. This will then go into the catch block, where the message is set
	if (!res.ok) throw Error('Failed getting Wordpress REST API');

	const data = await res.json();
	return data;
}
