import React, { useRef } from 'react';

import { gsap } from 'gsap';
import { useGSAP } from '@gsap/react';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { SplitText } from 'gsap/SplitText';

gsap.registerPlugin(useGSAP, ScrollTrigger, SplitText );

export const Branch = ({ service }) => {

	const item = useRef(null);

	useGSAP(
		() => {

			// console.log(item.current);

			const title = item.current.querySelector('h4');
			const paragraph = item.current.querySelector('p');
			const list = item.current.querySelectorAll('ul li');
			const lines = item.current.querySelectorAll('.services__line');

			// Reset if needed
            if(title.anim) {
                title.anim.progress(1).kill();
                title.split.revert();
            }

			title.split = new SplitText(title, {
				type: "chars, words",
				wordsClass: "word",
				tag: "span span"
			});

			gsap.set(title.split.chars, {display: "inline-block"});

			const tl = gsap.timeline({
				scrollTrigger: {
					trigger: item.current,
					// scrub: 1.5,
					start: 'top 50%',
					// markers: true,
				},
			});

			tl.from(lines, {
				ease: "circ.out",
				duration: 1,
				width: 0,
				stagger: 0.05,
			});				

			tl.from( title.split.chars, {
				duration: 0.15,
                ease: "circ.out",
                y: '105%',
                stagger: 0.05,}, 0.3);

			tl.from ( paragraph, {
				duration: 0.30,
				ease: "circ.out",
				opacity: 0,
				y: 50
			}, 0.5);	

			tl.from (list , {duration: 0.15,
                ease: "circ.out",
                x: '-100%',
                stagger: 0.05,
                opacity: 0}, 0.8)
		},
		{ dependencies: [] }
	);


	return (
		<section ref={item} className="services__content-branch">

			<div className="services__line services__line-top"></div>
			<div className="services__line services__line-bottom"></div>

			<h4 className="service__title">{service.title}</h4>

			<p>{service.text}</p>

			<ul>
				{service.items.map((skill) => {
					return <li key={skill.text}>{skill.text}</li>;
				})}
			</ul>

		</section>
	);
};
