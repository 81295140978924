import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';

import React, { useRef } from 'react';

import { gsap } from 'gsap';
import { useGSAP } from '@gsap/react';

import { Draggable } from 'gsap/Draggable';
import { InertiaPlugin } from 'gsap/InertiaPlugin';

/* The following plugin is a Club GSAP perk */
gsap.registerPlugin(Draggable, InertiaPlugin);
// gsap.registerPlugin(Draggable);

function TextSlider() {
	const helice = useRef(null);

	useGSAP(
		() => {
			// console.log(line.current);
			Draggable.create(helice.current, {
				type: 'rotation',
				inertia: true,
			});
		},
		{ dependencies: [] }
	);

	return (
		<Splide
			options={{
				perPage: 1,
				perMove: 1,
				arrows: false,
				pagination: true,
				autoplay: true,
				interval: 3000,
				type: 'loop',
			}}
		>
			<SplideSlide>
				<p className="">
					Creamos
					<strong style={{ color: 'var(--color-primary)' }}>a</strong>
					productos <br />
					digitales para marcas{' '}
					<strong
						ref={helice}
						style={{ color: 'var(--color-yellow)' }}
					>
						c
					</strong>{' '}
					<br />
					pymes y agencias{' '}
					<strong style={{ color: 'var(--color-secondary)' }}>
						g
					</strong>
				</p>
			</SplideSlide>
			<SplideSlide>
				<p className="">
					Creamos identidades{' '}
					<strong style={{ color: 'var(--color-yellow)' }}>f</strong>
					<br />
					únicas{' '}
					<strong style={{ color: 'var(--color-primary)' }}>
						n
					</strong>{' '}
					que te ayudan
					<br />a destacar{' '}
					<strong style={{ color: 'var(--color-secondary)' }}>
						b
					</strong>
				</p>
			</SplideSlide>
			<SplideSlide>
				<p className="">
					Diseñamos{' '}
					<strong style={{ color: 'var(--color-primary)' }}>u</strong>{' '}
					interfaces
					<br />
					visuales{' '}
					<strong style={{ color: 'var(--color-secondary)' }}>
						e
					</strong>{' '}
					atractivas y{' '}
					<strong style={{ color: 'var(--color-yellow)' }}>n</strong>
					<br />
					funcionales
				</p>
			</SplideSlide>
			<SplideSlide>
				<p className="">
					Transformamos{' '}
					<strong style={{ color: 'var(--color-secondary)' }}>
						d
					</strong>{' '}
					tus ideas
					<br />
					en{' '}
					<strong style={{ color: 'var(--color-primary)' }}>
						i
					</strong>{' '}
					experiencias visuales
					<br />
					impresionantes{' '}
					<strong style={{ color: 'var(--color-yellow)' }}>j</strong>
				</p>
			</SplideSlide>
			<SplideSlide>
				<p className="">
					Creamos{' '}
					<strong style={{ color: 'var(--color-primary)' }}>h</strong>{' '}
					animaciones
					<br />
					digitales vibrantes{' '}
					<strong style={{ color: 'var(--color-secondary)' }}>
						o
					</strong>
					<br />y{' '}
					<strong style={{ color: 'var(--color-yellow)' }}>m</strong>{' '}
					cautivadoras
				</p>
			</SplideSlide>
		</Splide>
	);
}

export default TextSlider;
