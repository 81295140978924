import { useEffect, useRef } from 'react';
import MediaQuery from 'react-responsive';
import TextSlider from '../../components/TextSlider';

function Banner({ data }) {
	useEffect(() => {}, []);
	const helice = useRef(null);

	return (
		<article className="banner">
			<MediaQuery minWidth={992}>
				<section className="banner--desktop">
					<TextSlider></TextSlider>
					<div className="banner__fixed">
						<p>
							Estudio de diseño y desarrollo. Mente fresca,
							trabajo fino
						</p>
					</div>
				</section>
			</MediaQuery>
			<MediaQuery maxWidth={991}>
				<section className="banner--mobile">
					<div className="banner__content">
						<p className="">Creamos</p>
						<p className="">productos</p>
						<p className="">digitales</p>
					</div>
					<div className="banner__symbols">
						<p className="s1">
							<strong style={{ color: 'var(--color-primary)' }}>
								a
							</strong>
						</p>
						<p className="s2">
							<strong
								ref={helice}
								style={{ color: 'var(--color-yellow)' }}
							>
								c
							</strong>
						</p>
						<p className="s3">
							<strong style={{ color: 'var(--color-secondary)' }}>
								g
							</strong>
						</p>
					</div>
					<div className="banner__fixed">
						<p>
							Estudio de diseño y desarrollo. Mente fresca,
							trabajo fino
						</p>
					</div>
				</section>
			</MediaQuery>
		</article>
	);
}

export default Banner;
