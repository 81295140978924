// import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
// import { ReactComponent as SvgLink } from '../../assets/icons/icon-link.svg';
import arrow from '../../assets/icons/icon-link.png';

function Card(props) {
	// const [image, setImage] = useState('');

	// console.log(data);

	// useEffect(
	// 	function () {
	// 		async function fetchModules() {
	// 			const res = await fetch(
	// 				`https://cms.frescoyfino.com/wp-json/wp/v2/media/${data.featured_media}`
	// 			);
	// 			const obj = await res.json();
	// 			const url = obj.source_url;
	// 			setImage(url);
	// 		}
	// 		fetchModules();

	// 		// console.log(loaded);
	// 	},
	// 	[data.featured_media, image]
	// );

	return (
		<Link
			to={props.url}
			target="_blank"
			className="card"
		>
			{/* <picture>
				<img
					src={image}
					alt={data.title.rendered}
				/>
			</picture> */}

			<section className="card__video">
				<video
					src={props.video}
					autoPlay
					muted
					loop
					type="video/mp4"
				></video>
			</section>

			<section className="card__title">
				<div>
					<h2>{props.title}</h2>
					<p>{props.categories}</p>
				</div>
				<img
					src={arrow}
					alt=""
				/>
			</section>
		</Link>
	);
}

export default Card;
