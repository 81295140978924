import Modules from '../modules/Modules.jsx';
import Lines from '../layout/Lines.jsx';

function Home() {
	return (
		<article>
			<Lines />

			<Modules />
		</article>
	);
}

export default Home;
