import { ReactComponent as Dot } from '../../assets/icons/icon-middot.svg';
import { Branch } from '../../components/Branch';

function Services({ data }) {

	return (
		<article className="services">
			<section className="services__title section__title">
				<Dot />
				<h3>SERVICIOS</h3>
			</section>

			<article className="services__content">
				{data.items.map((service) => {
					return (
						<Branch
							key={service.title}
							service={service}
						/>
					);
				})}
			</article>
		</article>
	);
}

export default Services;
