// import Modules from '../modules/Modules.jsx';

function Single() {
	return (
		<article>
			{/* <Modules /> */}
			Hola
		</article>
	);
}

export default Single;
