import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Home from './pages/Home';
import Single from './pages/Single';
import AppLayout from './layout/AppLayout';
import Error from './layout/Error';
import { loaderAll } from './loaders/loaderWP';
import './scss/style.scss';

const router = createBrowserRouter([
	{
		element: <AppLayout />,
		path: '/',
		errorElement: <Error />,
		children: [
			{
				path: '/',
				element: <Home />,
				loader: loaderAll,
			},
			{
				path: '/single',
				element: <Single />,
			},
		],
	},
]);

function App() {
	return <RouterProvider router={router} />;
}

export default App;
