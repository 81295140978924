import { Outlet, useNavigation } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import Header from './Header.jsx';
import Footer from './Footer.jsx';
import Loader from './Loader';
import Reviews from '../modules/Reviews/Reviews.jsx';

function Home() {
	const navigation = useNavigation();
	const loaderRef = useRef(null);

	// console.log(navigation);

	useEffect(() => {
		const isReady = navigation.state === 'idle';

		function handleLoader() {
			setTimeout(() => {
				loaderRef.current.classList.add('hide');
				loaderRef.current.addEventListener('transitionend', (e) => {
					loaderRef.current.style.display = 'none';
				});
			}, 600);
		}

		if (isReady) {
			handleLoader();
		}
	});

	return (
		<>
			<Loader ref={loaderRef} />

			<Header />

			<main>
				<Outlet />
			</main>

			<Reviews />

			<Footer />
		</>
	);
}

export default Home;
