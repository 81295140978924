import Banner from './Banner/Banner';
import Grid from './Grid/Grid';
import Services from './Services/Services';
import Works from './Works/Works';
import { useLoaderData } from 'react-router-dom';

const Modules = () => {
	const data = useLoaderData();

	return (
		<main>
			{data.acf.modules.map((module) => {
				if (module.acf_fc_layout === 'banner') {
					return (
						<Banner
							data={module}
							key={module.acf_fc_layout}
						/>
					);
				} else if (module.acf_fc_layout === 'grid') {
					return (
						<Grid
							data={module}
							key={module.acf_fc_layout}
						/>
					);
				} else if (module.acf_fc_layout === 'services') {
					return (
						<Services
							data={module}
							key={module.acf_fc_layout}
						/>
					);
				} else if (module.acf_fc_layout === 'slider') {
					return (
						<Works
							data={module}
							key={module.acf_fc_layout}
						/>
					);
				} else {
					return null;
				}
			})}
		</main>
	);
};

export default Modules;
