import React from 'react';
import { ReactComponent as Dot } from '../../assets/icons/icon-middot.svg';

export const Content = React.forwardRef((props, ref) => {
	return (
		<section
			ref={ref}
			className="content"
		>
			<div className="section__title">
				<Dot />
				<h3>TRABAJOS</h3>
			</div>
			<h4>
				Proyectos
				<br />
				destacados{' '}
				<strong style={{ color: 'var(--color-primary)' }}>k</strong>
			</h4>
			{/* <p className="content__body">{props.data.content.body}</p> */}
		</section>
	);
});
